import React from "react";
import ReactDOM from "react-dom";
import { StateMachineProvider, createStore  } from "little-state-machine";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
//import config from "./auth_config.json";
import history from "./utils/history";

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

createStore({
  data: {}
});

ReactDOM.render(
  <Auth0Provider
    domain={'dev-z6ldztcl.us.auth0.com'}
    clientId={"67yH6KgXZFwfWj6ZP6yX1GwDPezA2Srz"}
  //  audience={config.audience}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
   >
    <StateMachineProvider>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
    </StateMachineProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
