import React,{Suspense } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { QueryCache, ReactQueryCacheProvider } from 'react-query'

import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";

import Home from "./views/Home";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";


// styles
import "./App.css";
// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();


const Admin = React.lazy(() => import('./views/Admin'));

const Login = React.lazy(() => import('./views/Login'));

const queryCache = new QueryCache()

const App = () => {
  const { isLoading, user } = useAuth0();
 
  if (isLoading) {
    return <Loading />;
  }

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Router history={history} >
        <div id="app" style={{height:'100vh'}}>
          <Suspense fallback={<Loading/>}>
            <Switch>
              <PrivateRoute path="/warehouse-login" component={Login} />
              <PrivateRoute path="/" component={Admin} />
              
            </Switch>
           </Suspense> 
        </div>
      </Router>
    </ReactQueryCacheProvider>
  );
};

export default App;
