import React from "react";
import logo from "../assets/logo/rsz_world-logo-white-min.png";
import logo_us from "../assets/logo/rsz_us-logo-min.png";
import logo_ca from "../assets/logo/rsz_ca-logo-min.png";
import background from "../assets/background/background_v4-min.png";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(2),
    },
  },
}));

function Hero() {
	const classes = useStyles();
	fetch('https://freegeoip.app/json/')
	.then(response => response.json())
  .then(data => {
  	if (data.country_code === 'CA') {
  		window.location.replace('https://virtualruncanada.ca/');
  	} else if (data.country_code === 'US'){
  		window.location.replace('https://virtualrun.us/');
  	} else {
  		return(
		<div className="main" style={{'backgroundImage': 'url('+background+')',
		    'backgroundRepeat': 'no-repeat',
		   	'backgroundSize': 'cover',
		    'backgroundPosition': 'center',
		    'height': '100vh'}}>
		
		<Grid
		  container
		  direction="row"
		  justify="flex-end"
		  alignItems="flex-start"
		>
			<Grid item sm={12}>
				<img src={logo} alt="virtual run world " width="auto" height="130em" style={{float:'right'}} />
			</Grid>


		</Grid>
		<Container maxWidth="lg" style={{marginTop:'7em'}}>

			<Grid
			  container
			  direction="row"
			  justify="center"
			  alignItems="center"
			>


				<Paper elevation={3} className={classes.root} >

						<Grid item sm={12}>

							<Button 
								variant="outlined" color="secondary"
								
							>
								<a href='https://virtualrun.us'>
									<img src={logo_us} alt="virtual run canada" width="100%" height="auto" />
								</a>
							</Button>

						</Grid>

						<Grid item sm={12}>
							<Button 
								variant="outlined" color="secondary"
							>
								<a href='https://virtualruncanada.ca'>
									<img src={logo_ca} alt="virtual run usa" width="100%" height="auto" />
								</a>
							</Button>
						</Grid>
			    </Paper>
			</Grid>

		</Container>
			
	</div>

	 
	)
  		
  	}
  });

	
}

export default Hero;
